import {combineReducers} from 'redux'
import {connectRouter} from 'connected-react-router'
import {AppState, Datum, initial_app, StateActions, Types} from "./types";
import {createBrowserHistory} from "history";
import update from 'react-addons-update'

function system_reducer(state = initial_app, action: StateActions): AppState {
    if (action.type === Types.ADD_DUMMY) {
        if (action.payload.clear) {
            return {...state, data: update(Array<Datum>(), {$push: action.payload.data})}
        } else if (action.payload.index >= 0) {
            return {...state, data: update(state.data, {[action.payload.index]: {$set: action.payload.data[0]}})}
        } else {
            return {...state, data: update(state.data, {$push: action.payload.data})}
        }
    } else if (action.type === Types.UPDATE_CLIENT) {
        return {...state, client: action.payload}
    } else {
        return state
    }
}

const root_reducer = combineReducers({
    router: connectRouter(createBrowserHistory()),
    data: system_reducer,
    client: system_reducer,
});

export default root_reducer;
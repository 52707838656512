import React from "react";
import {Bar, BarChart, CartesianGrid, Legend, Tooltip, XAxis, YAxis,} from 'recharts';

export default function SubscriptionChart(props: { dimensions: { width: number, height: number } }) {
    const data = [
        {name: 'Page A', pv: 2400, amt: 2400,},
        {name: 'Page B', pv: 1398, amt: 2210,},
        {name: 'Page C', pv: 9800, amt: 2290,},
        {name: 'Page D', pv: 3908, amt: 2000,},
        {name: 'Page E', pv: 4800, amt: 2181,},
        {name: 'Page F', pv: 3800, amt: 2500,},
        {name: 'Page G', pv: 4300, amt: 2100,},
    ];

    return (<BarChart width={props.dimensions.width} height={props.dimensions.height} data={data}
                      margin={{top: 0, right: 0, left: 0, bottom: 0,}}>
        <CartesianGrid strokeDasharray="3 3"/>
        <XAxis dataKey="name"/>
        <YAxis/>
        <Tooltip/>
        <Legend/>
        <Bar dataKey="pv" stackId="a" fill="#8884d8"/>
        <Bar dataKey="amt" stackId="a" fill="#82ca9d"/>
    </BarChart>)
}
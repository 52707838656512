import React from 'react'
import {CombinedProps} from "../../store/types";
import {Table} from "semantic-ui-react";
import {TablePagination} from "../utils/Utils";
import {Fetch} from "../utils/Request";

export default function OrdersFinalised(props: { system: CombinedProps }): any {
    const [fetch, setFetch] = React.useState<Fetch>({limit: 13, start: 0, total: 0, page: 1, pages: 0})

    return (
        <div className="container-fluid p-0">
            <div className="row m-0">
                <div className="col-12 pr-1 search_bar">

                </div>

                <div className="col-12 pr-1 item_container">
                    <div className="table_container">
                        <Table celled striped compact size='small' inverted color='grey' selectable>
                            <Table.Header>
                                <Table.Row>
                                    <Table.HeaderCell width={1}>No.</Table.HeaderCell>
                                    <Table.HeaderCell>Client Name</Table.HeaderCell>
                                    <Table.HeaderCell>Client Contact</Table.HeaderCell>
                                    <Table.HeaderCell>Shipping Address</Table.HeaderCell>
                                    <Table.HeaderCell>Payment Method</Table.HeaderCell>
                                    <Table.HeaderCell>Items Cost</Table.HeaderCell>
                                    <Table.HeaderCell>Date Ordered</Table.HeaderCell>
                                    <Table.HeaderCell>Date Shipped</Table.HeaderCell>
                                    <Table.HeaderCell>Date Delivered</Table.HeaderCell>
                                </Table.Row>
                            </Table.Header>
                        </Table>
                    </div>

                    <div className="table_footer">
                        <TablePagination
                            total={fetch.total}
                            change={(page: number) => {

                            }}/>
                    </div>
                </div>
            </div>
        </div>
    )
}


import React from 'react'
import {CombinedProps} from "../../store/types";
import SubscriptionChart from "./charts/SubscriptionChart";
import SubjectViewChart from "./charts/SubjectViewChart";

export default function AdminDashboard(props: { system: CombinedProps }): any {
    const half_graph = React.createRef<HTMLDivElement>()
    const full_graph = React.createRef<HTMLDivElement>()
    const [halfDimensions, setHalfDimensions] = React.useState({width: 0, height: 0});
    const [fullDimensions, setFullDimensions] = React.useState({width: 0, height: 0});
    React.useLayoutEffect(() => {
        if (half_graph.current) {
            setHalfDimensions({width: half_graph.current.offsetWidth, height: half_graph.current.offsetHeight});
        }
        if (full_graph.current) {
            setFullDimensions({width: full_graph.current.offsetWidth, height: full_graph.current.offsetHeight});
        }
    }, []);

    return (
        <div className="container-fluid p-0">
            <div className="row m-0">
                {/*Orders Graph*/}
                <div className="col-12 p-2">
                    <div className="dashboard_item" ref={full_graph}>
                        <SubscriptionChart dimensions={fullDimensions}/>
                    </div>
                </div>

                {/*Best Seller*/}
                <div className="col-12 col-md-6 p-2">
                    <div className="dashboard_item" ref={half_graph}>
                        <SubjectViewChart dimensions={halfDimensions}/>
                    </div>
                </div>

                {/*Best Category*/}
                <div className="col-12 col-md-6 p-2">
                    <div className="dashboard_item" ref={half_graph}>
                        <SubjectViewChart dimensions={halfDimensions}/>
                    </div>
                </div>

                {/*Price Range*/}
                <div className="col-12 col-md-6 p-2">
                    <div className="dashboard_item" ref={half_graph}>
                        <SubjectViewChart dimensions={halfDimensions}/>
                    </div>
                </div>

                {/*Total Sales*/}
                <div className="col-12 col-md-6 p-2">
                    <div className="dashboard_item" ref={half_graph}>
                        <SubjectViewChart dimensions={halfDimensions}/>
                    </div>
                </div>

                {/*New Users*/}
                <div className="col-12 col-md-6 p-2">
                    <div className="dashboard_item" ref={half_graph}>
                        <SubjectViewChart dimensions={halfDimensions}/>
                    </div>
                </div>

                {/*User Interaction*/}
                <div className="col-12 col-md-6 p-2">
                    <div className="dashboard_item" ref={half_graph}>
                        <SubjectViewChart dimensions={halfDimensions}/>
                    </div>
                </div>
            </div>
        </div>
    )
}


import React from "react";
import {Button, Form, Modal, Table, TextArea} from "semantic-ui-react";
import {MessageProps, MyInput, MyLabel, MyMessage, Utils} from "../../utils/Utils";
import {ReqAdmin} from "../../utils/Request";
import update from "react-addons-update";

export interface Brand {
    brand_id: number
    brand_name: string
    brand_desc: string
}

export default function Brands(props: {
    show: boolean, close: () => void, brands: Array<Brand>, setBrands: (brands: Array<Brand>) => void
}) {
    const [message, setMessage] = React.useState<MessageProps>({active: false, message: "", type: 'info'})
    const [saving, setSaving] = React.useState(false)
    const initial_brand: Brand = {brand_desc: "", brand_id: 0, brand_name: ""}

    const [brand, setBrand] = React.useState<Brand>(initial_brand)
    const handle_brand = (name: string, value: string | number) => {
        setBrand({...brand, [name]: value})
    }

    const save_brand = () => {
        setSaving(true)
        ReqAdmin.save_brands({brand_desc: brand.brand_desc, brand_id: brand.brand_id, brand_name: brand.brand_name})
            .then((result) => {
                if (result.data.hasOwnProperty("code")) {
                    if (result.data['code'] === 1) {
                        const new_brand = {...brand, 'brand_id': result.data['brand_id']}
                        if (brand.brand_id === 0) {
                            props.setBrands([...props.brands, new_brand])
                        } else {
                            for (let index = 0; index < props.brands.length; index++) {
                                if (props.brands[index].brand_id === brand.brand_id) {
                                    props.setBrands(update(props.brands, {[index]: {$set: new_brand}}))
                                    break
                                }
                            }
                        }
                        setBrand(new_brand)
                        setMessage({active: true, message: "Successfully saved brand", type: 'success'})
                    } else if (result.data['code'] === 1) {
                        setMessage({active: true, message: "Brand was already saved", type: 'error'})
                    }
                } else {
                    setMessage({active: true, message: "Error while saving brand, please retry", type: 'error'})
                }
                setSaving(false)
            })
            .catch(() => {
                setMessage({active: true, message: "Error while saving brand, please retry", type: 'error'})
                setSaving(false)
            })
    }

    return (
        <>
            <MyMessage message={message.message} type={message.type} active={message.active}
                       close={() => setMessage({...message, 'active': false})}/>

            <Modal size='small' open={props.show} onClose={props.close}
                   centered={false}>

                <div className="category_container">
                    <div className="row m-0 h-100">
                        <div className="col-12 col-md-7 p-1 item_form">
                            <div>
                                <Table celled striped compact size='small' inverted color='grey' selectable>
                                    <Table.Header>
                                        <Table.Row>
                                            <Table.HeaderCell width={3}>No.</Table.HeaderCell>
                                            <Table.HeaderCell>Brand Name</Table.HeaderCell>
                                        </Table.Row>
                                    </Table.Header>
                                    <Table.Body>
                                        {
                                            props.brands.map((brand, index) =>
                                                <Table.Row key={brand.brand_id} onClick={() => setBrand(brand)}>
                                                    <Table.Cell width={3}>{Utils.row_number(index)}</Table.Cell>
                                                    <Table.Cell>{brand.brand_name}</Table.Cell>
                                                </Table.Row>
                                            )
                                        }
                                    </Table.Body>
                                </Table>
                            </div>
                        </div>
                        <div className="col-12 col-md-5 p-1 item_form">
                            <div>
                                <div className="form_container">
                                    <MyLabel label="Brand Name"/>
                                    <MyInput placeholder="Enter brand name" name="brand_name"
                                             value={brand.brand_name} change={handle_brand}/>

                                    <MyLabel label="Brand Description"/>
                                    <Form>
                                        <TextArea placeholder='Enter brand description' rows={5}
                                                  value={brand.brand_desc}
                                                  onChange={((event, data) => {
                                                      handle_brand('brand_desc', data.value as string)
                                                  })}/>
                                    </Form>
                                </div>

                                <div className="button_row">
                                    <div className="row m-0">
                                        <div className="col-6 pl-0 pr-1">
                                            <Button basic fluid size='tiny' content="Clear Data" icon="trash" labelPosition="left"
                                                    onClick={() => setBrand(initial_brand)} disabled={saving}/>
                                        </div>
                                        <div className="col-6 pl-1 pr-0">
                                            <Button basic fluid size='tiny' onClick={save_brand} content="Save Brand"
                                                    icon="save" labelPosition="left" disabled={saving} loading={saving}/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </Modal>
        </>
    )
}
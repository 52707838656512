import React from 'react'
import {CombinedProps, mapDispatchToProps, mapStateToProps} from "../../store/types";
import axios from 'axios'
import {connect} from "react-redux";
import {Utils} from "./Utils";

export class ReqClient {
    static complete_login(data: { user_name: string, user_avatar: string, user_email: string, fcm_token: string }) {
        return axios({method: 'post', url: 'api/login', data: Utils.post_data(data)});
    }
}

export class ReqAdmin {
    static get_users(params: { fetch: Fetch, name: string, email: string, min: string, max: string }) {
        return axios({method: 'get', url: 'admin/users', params: params});
    }

    static save_categories(data: { category_id: number, category_name: string, category_desc: string }) {
        return axios({method: 'post', url: 'admin/categories/save', data: Utils.post_data(data)});
    }

    static get_categories() {
        return axios({method: 'get', url: 'admin/categories', params: {}});
    }

    static save_brands(data: { brand_id: number, brand_name: string, brand_desc: string }) {
        return axios({method: 'post', url: 'admin/brands/save', data: Utils.post_data(data)});
    }

    static get_brands() {
        return axios({method: 'get', url: 'admin/brands', params: {}});
    }

    static save_item(data: FormData) {
        return axios({method: 'post', url: 'admin/items/save', data: data});
    }

    static get_items() {
        return axios({method: 'get', url: 'admin/items', params: {}});
    }
}

export interface Fetch {
    start: number
    limit: number
    total: number
    page: number
    pages: number
}

function Request(props: CombinedProps): any {

    const init_axios = () => {
        axios.defaults.baseURL = Utils.BASE_URL;
        axios.defaults.headers['common']['Authorization'] = "Pande William";
        axios.defaults.headers['common']['Content-Type'] = 'application/json';
        axios.defaults.headers['common']['DeviceInfo'] = "";
        axios.defaults.headers['common']['UserToken'] = "";
        axios.defaults.timeout = 1000 * 60

        axios.interceptors.response.use(function (response) {
            console.log(response.data)
            console.log(response)
            if (response.data.hasOwnProperty("code") && response.data['code'] === 401) {
                alert('Your login session expired, please logout and login again to continue')
            }
            return response;
        }, function (error) {
            console.log(error)
            return Promise.reject(error);
        });
    }

    React.useEffect(() => {
        init_axios()
    }, [])

    return (
        <></>
    )
}

export default connect(mapStateToProps, mapDispatchToProps)(Request)
import firebase from "firebase";
import React from "react";
import {connect} from "react-redux";
import {CombinedProps, mapDispatchToProps, mapStateToProps} from "../../store/types";

// @ts-ignore

function AppFirebase(props: CombinedProps): any {
    if (!firebase.apps.length) {
        firebase.initializeApp({
            apiKey: "AIzaSyBrq3LVE9t6EMUWaner268phapmaeY2QwM",
            authDomain: "wooow-shoes.firebaseapp.com",
            databaseURL: "https://wooow-shoes.firebaseio.com",
            projectId: "wooow-shoes",
            storageBucket: "wooow-shoes.appspot.com",
            messagingSenderId: "117801619546",
            appId: "1:117801619546:web:ad97f0ef1c24334e49c863",
            measurementId: "G-M69RE567G4"
        });
        //firebase.analytics();
    }
    const messaging = firebase.messaging();

    const send_token_to_server = (web_token: string) => {

    }

    const get_web_token = () => {
        if (Notification.permission === 'granted') {
            // Get Instance ID token. Initially this makes a network call, once retrieved
            // subsequent calls to getToken will return from cache.
            messaging.getToken()
                .then((currentToken) => {
                    localStorage.setItem("fcm_token", currentToken)
                    console.log(currentToken)
                })
                .catch((err) => {
                    console.error(err)
                });
        }
    }

    const refresh_token_listener = () => {
        // [START refresh_token]
        // Callback fired if Instance ID token is updated.
        messaging.onTokenRefresh(() => {
            messaging.getToken().then((refreshedToken) => {
                console.log('Token refreshed.');
                // Indicate that the new Instance ID token has not yet been sent to the app server.
                //update_token_status(false);
                // Send Instance ID token to app server.
                send_token_to_server(refreshedToken);
            }).catch((err) => {
                console.log(err);
            });
        });
        // [END refresh_token]

        messaging.onMessage(function (payload) {
            const data = payload.data;
            console.log(data);
        });
    }

    React.useEffect(() => {
        console.error("Registering", "Registering service worker")
        console.error("serviceWorker", ("serviceWorker" in navigator))
        if ("serviceWorker" in navigator) {
            navigator.serviceWorker
                .register('/firebase-messaging-sw.js')
                .then((registration) => {
                    messaging.useServiceWorker(registration);
                    get_web_token();
                })
                .catch(error => {
                    console.error(error);
                });
        }

        document.onclick = function () {
            if (Notification.permission !== 'granted') {
                Notification.requestPermission()
                    .then((permission) => {
                        if (permission === 'granted') {
                            get_web_token();
                        }
                    });
            }
        };
    }, []);

    refresh_token_listener();

    return (<></>)
}

export default connect(mapStateToProps, mapDispatchToProps)(AppFirebase)
import React from 'react'
import {CombinedProps} from "../../store/types";

export default function Reports(props: { system: CombinedProps }): any {
    return (
        <div className="container-fluid p-0">
            <div className="row m-0">
                <div className="col-12 pr-1 search_bar">

                </div>

                <div className="col-12 pr-1 item_container">

                </div>
            </div>
        </div>
    )
}


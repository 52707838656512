export interface Client {
    client_id: number
    client_name: string
    client_email: string
    client_gender: string
    user_avatar: string
    user_token: string
}

export const initial_client: Client = {
    client_email: "", client_gender: "", client_id: 0, client_name: "", user_avatar: "", user_token: ""
}
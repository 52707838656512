import React from 'react';
import {Route, Switch} from 'react-router'
import NoMatch from "./utils/NoMatch";
import AdminNav from "./utils/nav/AdminNav";

export default function App() {
    return (
        <Switch>
            <Route exact path="/" component={AdminNav}/>
            <Route exact path={Routes.route_dashboard} component={AdminNav}/>

            <Route component={NoMatch}/>
        </Switch>
    )
};

export class Routes {
    static route_dashboard = "/admin"
}



import React from 'react'
import {CombinedProps} from "../../store/types";
import {Button, Icon, Table} from "semantic-ui-react";
import {TablePagination, Utils} from "../utils/Utils";
import {Fetch, ReqAdmin} from "../utils/Request";

interface Client {
    client_email: string
    client_gender: string
    client_id: number
    client_name: string
    date_created: string
    is_admin: 0 | 1
    user_avatar: string
}

export default function Clients(props: { system: CombinedProps }): any {
    const [searching, setSearching] = React.useState(false)
    const [fetch, setFetch] = React.useState<Fetch>({limit: 13, start: 0, total: 0, page: 1, pages: 0})
    const [search, setSearch] = React.useState({email: "", max: "", min: "", name: ""})

    const [clients, setClients] = React.useState<Array<Client>>([])

    const get_users = () => {
        setSearching(true)
        ReqAdmin.get_users({email: search.email, fetch: fetch, max: search.max, min: search.min, name: search.name})
            .then((result) => {
                if (result.data.hasOwnProperty("users")) {
                    setClients(result.data['users'])
                }
                setSearching(false)
            })
            .catch(() => {
                setSearching(false)
            })
    }

    React.useEffect(() => {
        get_users()
    }, [])

    return (
        <div className="container-fluid p-0">
            <div className="row m-0">
                <div className="col-12 pr-1 search_bar">
                    <Button animated floated='right' size='mini' loading={searching} disabled={searching}
                            onClick={() => {
                                if (fetch.start === 0) {
                                    get_users()
                                } else {
                                    setFetch({...fetch, 'total': 0, 'start': 0})
                                }
                            }}>
                        <Button.Content visible>Reload</Button.Content>
                        <Button.Content hidden>
                            <Icon name='search'/>
                        </Button.Content>
                    </Button>

                </div>

                <div className="col-12 pr-1 item_container">
                    <div className="table_container">
                        <Table celled striped compact size='small' inverted color='grey' selectable>
                            <Table.Header>
                                <Table.Row>
                                    <Table.HeaderCell width={1}>No.</Table.HeaderCell>
                                    <Table.HeaderCell width={3}>Client Name</Table.HeaderCell>
                                    <Table.HeaderCell width={3}>Email Address</Table.HeaderCell>
                                    <Table.HeaderCell width={2}>Client Gender</Table.HeaderCell>
                                    <Table.HeaderCell width={2}>Is Admin</Table.HeaderCell>
                                    <Table.HeaderCell width={2}>Date Created</Table.HeaderCell>
                                </Table.Row>
                            </Table.Header>
                            <Table.Body>
                                {
                                    clients.map((client, index) =>
                                        <Table.Row
                                            key={client.client_id}
                                            onClick={() => {

                                            }}>
                                            <Table.Cell width={1}>{Utils.row_number(index, fetch)}</Table.Cell>
                                            <Table.Cell width={3}>{client.client_name}</Table.Cell>
                                            <Table.Cell width={3}>{client.client_email}</Table.Cell>
                                            <Table.Cell width={2}>{client.client_gender}</Table.Cell>
                                            <Table.Cell width={2}>{client.is_admin ? 'Yes' : 'No'}</Table.Cell>
                                            <Table.Cell width={2}>{Utils.localise_date(client.date_created)}</Table.Cell>
                                        </Table.Row>
                                    )
                                }
                            </Table.Body>
                        </Table>
                    </div>

                    <div className="table_footer">
                        <TablePagination
                            total={fetch.total}
                            change={(page: number) => {

                            }}/>
                    </div>
                </div>
            </div>
        </div>
    )
}


import {Dropdown, Icon, Input, Label, Modal, Pagination} from "semantic-ui-react";
import React from "react";
import {Fetch} from "./Request";

export type select_type = string | number | boolean | (string | number | boolean)[] | undefined

export interface SelectData {
    text: string
    value: string | number
}

export function MySelect(
    props: {
        change: (value: select_type, text: string) => void, name: string, value: select_type, placeholder: string,
        options: Array<{ text: string, value: string | number }>, disabled?: boolean, className?: string
    }) {
    return (
        <>
            <Dropdown
                size="mini" fluid selection search={true} value={props.value} options={props.options}
                disabled={props.disabled !== undefined ? props.disabled : false} placeholder={props.placeholder}
                className={props.className !== undefined ? props.className : ''}
                onChange={((event, data) => {
                    props.change(data.value, data.text as string)
                })}/>
        </>
    )
}

export interface MessageProps {
    type: 'success' | 'error' | 'info'
    active: boolean
    message: string
}

export function MyMessage(props: { message: string, type: 'success' | 'error' | 'info', close: () => void, active: boolean }) {
    const [color, setColor] = React.useState<'blue' | 'red' | 'green'>('blue')
    const [icon, setIcon] = React.useState<'checkmark' | 'info' | 'cancel'>('info')

    const get_color = () => {
        if (props.type === 'success') {
            return 'green'
        } else if (props.type === 'error') {
            return 'red'
        }
        return 'blue'
    }

    const get_icon = () => {
        if (props.type === 'success') {
            return 'checkmark'
        } else if (props.type === 'error') {
            return 'cancel'
        }
        return 'info'
    }

    React.useEffect(() => {
        setColor(get_color)
        setIcon(get_icon)
    }, [])

    React.useEffect(() => {
        setColor(get_color)
        setIcon(get_icon)
    }, [props.type])

    return (
        <>
            <Modal size='mini' open={props.active} onClose={props.close} centered={false}>
                <div className="dialog_container">
                    <div className="dialog_icon"><Icon name={icon} color={color} size='large'/></div>
                    <div className="dialog_message">{props.message}</div>
                </div>
            </Modal>
        </>
    )
}

export function MyLabel(props: { label: string, class?: string }) {
    return (
        <div className={props.class !== undefined ? props.class : 'label'}>
            <label>{props.label}</label>
        </div>
    )
}

export function MyInput(
    props: {
        change?: (name: string, value: string) => void, placeholder: string, name: string,
        value: string, disabled?: boolean, style?: Object, className?: string
    }) {
    return (
        <>
            <Input placeholder={props.placeholder} fluid size="mini" name={props.name} value={props.value}
                   style={props.style === undefined ? {marginBottom: '15px'} : props.style}
                   disabled={props.disabled !== undefined && props.disabled}
                   className={props.className !== undefined ? props.className : ''}
                   onChange={((event, data) => {
                       if (props.change !== undefined) {
                           props.change(event.target.name, data.value)
                       }
                   })}/>
        </>
    )
}

export function TablePagination(props: { total: number, change: (page: number) => void }) {
    return (
        <Pagination
            defaultActivePage={1}
            ellipsisItem={{content: <Icon name='ellipsis horizontal'/>, icon: true}}
            firstItem={{content: <Icon name='angle double left'/>, icon: true}}
            lastItem={{content: <Icon name='angle double right'/>, icon: true}}
            prevItem={{content: <Icon name='angle left'/>, icon: true}}
            nextItem={{content: <Icon name='angle right'/>, icon: true}}
            totalPages={props.total} size='tiny'
            onPageChange={((event, data) => {
                props.change(data.activePage as number)
            })}
        />
    )
}

export class InputFile {
    file_src: string;
    file: File | undefined;

    constructor(file_path: string, file?: File) {
        this.file_src = file_path;
        this.file = file
    }
}

export function MyDateInput(
    props: {
        title: string, minDate?: string, maxDate?: string, value: string, name: string,
        change: (name: string, value: string) => void, disabled?: boolean
    }
) {
    return (
        <>
            <input className="date_input" name={props.name} value={props.value}
                   type="date" max={props.maxDate} min={props.minDate}
                   disabled={props.disabled !== undefined ? props.disabled : false}
                   onChange={(event) => props.change(event.target.name, event.target.value)}/>
        </>
    )
}

export class Utils {
    //static BASE_URL = "http://127.0.0.1:8000/"
    
    static BASE_URL = "https://wapi.projectoneafrica.com/"

    static fcm_token(): string {
        const fcm_token = localStorage.getItem("fcm_token")
        return fcm_token == null ? "" : fcm_token
    }

    public static comma_number(value: number, pre?: string, post?: string): string {
        const prefix = pre === undefined ? 'shs ' : pre
        const postfix = post === undefined ? '' : post
        return prefix + value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") + postfix
    }

    public static localise_date(string: string): string {
        const date = new Date(string.replace(/-/g, "/"))
        return date.toLocaleDateString(undefined,
            {weekday: 'short', year: 'numeric', month: 'short', day: '2-digit'})
    }

    static row_number(index: number, fetch?: Fetch) {
        let number = (index + 1)
        if (fetch !== undefined) {
            number = (index + 1) + (fetch.limit * (fetch.page - 1))
        }

        if (index === 0) {
            return <Label ribbon> {number} </Label>
        } else {
            return number
        }
    }

    static post_data(data: any) {
        const form = new FormData();
        Object.entries(data).forEach(([prop, val]) => {
            form.append(prop, val as string)
        })
        return form
    }

    static format_file(file: File) {
        return new Promise<InputFile>((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => {
                resolve(new InputFile(reader.result as string, file))
            };
            reader.onerror = error => reject(null);
        });
    }
}
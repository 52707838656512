import React from 'react'
import {CombinedProps} from "../../store/types";
import {Button, Icon, Table} from "semantic-ui-react";
import {MyInput, TablePagination, Utils} from "../utils/Utils";
import {Fetch, ReqAdmin} from "../utils/Request";
import Categories, {Category} from "./misc/Categories";
import ModalStock, {initial_item, Item, item_image} from "./misc/ModalStock";
import Brands, {Brand} from "./misc/Brands";

export default function ShoeStocks(props: { system: CombinedProps }): any {
    let fetch: Fetch = {limit: 13, start: 0, total: 0, page: 1, pages: 0}
    const [searching, setSearching] = React.useState(false)
    const [search, setSearch] = React.useState({category: "", max: "", min: "", name: ""})

    const handle_search = (name: string, value: string) => {
        setSearch({...search, [name]: value})
    }

    const [categories, setCategories] = React.useState<Array<Category>>([])
    const [showCategory, setShowCategory] = React.useState(false)
    const [brands, setBrands] = React.useState<Array<Brand>>([])
    const [showBrand, setShowBrand] = React.useState(false)
    const [items, setItems] = React.useState<Array<Item>>([])

    const load_data = () => {
        setSearching(true)
        ReqAdmin.get_categories()
            .then((result) => {
                if (result.data.hasOwnProperty("categories")) {
                    setCategories(result.data['categories'])
                }
            })

        ReqAdmin.get_brands()
            .then((result) => {
                if (result.data.hasOwnProperty("brands")) {
                    setBrands(result.data['brands'])
                }
            })

        ReqAdmin.get_items()
            .then((result) => {
                if (result.data.hasOwnProperty("items")) {
                    let items_: Array<Item> = [];
                    (result.data['items'] as Array<any>).forEach((item: any) => {
                        let images: Array<item_image> = [];
                        (item['images'] as Array<string>).forEach((value) => {
                            images = [...images, {path: Utils.BASE_URL + value}]
                        })

                        items_ = [...items_, {
                            brand_id: item['brand_id'],
                            category_id: item['category_id'],
                            descriptions: item['descriptions'],
                            images: images,
                            item_desc: item['item_desc'],
                            item_discount: item['item_discount'],
                            item_id: item['item_id'],
                            item_name: item['item_name'],
                            item_price: item['item_price'],
                            section: item['section'],
                            brand_name: item['brand_name'],
                            category_name: item['category_name']
                        }]
                    })
                    setItems(items_)
                }
                setSearching(false)
            })
    }

    const [showStock, setShowStock] = React.useState({show: false, item: initial_item})
    const get_stock = () => {
        setSearching(true)
    }

    React.useEffect(() => {
        load_data()
    }, [])

    return (
        <>
            <Categories show={showCategory} close={() => setShowCategory(false)} categories={categories}
                        setCategories={setCategories}/>

            <Brands show={showBrand} close={() => setShowBrand(false)} brands={brands} setBrands={setBrands}/>

            <ModalStock
                show={showStock.show} close={() => setShowStock({...showStock, show: false})}
                categories={categories} brands={brands} item={showStock.item}
                update={(item) => {
                    let saved = false;
                    let _items: Array<Item> = [];
                    _items.forEach((_item) => {
                        if (_item.item_id === item.item_id) {
                            _items = [..._items, item]
                            saved = true
                        } else {
                            _items = [..._items, _item]
                        }
                    })
                    if (!saved) {
                        _items = [..._items, item]
                    }
                    setItems(_items)
                }}/>

            <div className="container-fluid p-0">
                <div className="row m-0">
                    <div className="col-12 pr-1 search_bar">

                        <Button animated floated='right' size='mini' loading={searching} disabled={searching}
                                onClick={() => get_stock()}>
                            <Button.Content visible>Reload</Button.Content>
                            <Button.Content hidden>
                                <Icon name='search'/>
                            </Button.Content>
                        </Button>

                        <Button floated='right' size='mini' onClick={() => setShowCategory(true)}>Categories</Button>

                        <Button floated='right' size='mini' onClick={() => setShowBrand(true)}>Brands</Button>

                        <Button floated='right' size='mini'
                                onClick={() => setShowStock({item: initial_item, show: true})}>Add Stock</Button>

                        <div className="search_container">
                            <div>
                                <MyInput placeholder="Search by item name" name="name"
                                         value={search.name} change={handle_search}/>
                            </div>

                            <div>
                                <MyInput placeholder="Search by item category" name="category"
                                         value={search.category} change={handle_search}/>
                            </div>
                        </div>
                    </div>

                    <div className="col-12 pr-1 item_container">
                        <div className="table_container">
                            <Table celled striped compact size='small' inverted color='grey' selectable>
                                <Table.Header>
                                    <Table.Row>
                                        <Table.HeaderCell width={1}>No.</Table.HeaderCell>
                                        <Table.HeaderCell>Item Name</Table.HeaderCell>
                                        <Table.HeaderCell>Brand</Table.HeaderCell>
                                        <Table.HeaderCell>Category</Table.HeaderCell>
                                        <Table.HeaderCell>Section</Table.HeaderCell>
                                        <Table.HeaderCell width={2}>Price</Table.HeaderCell>
                                        <Table.HeaderCell width={2}>Discount</Table.HeaderCell>
                                    </Table.Row>
                                </Table.Header>

                                <Table.Body>
                                    {
                                        items.map((_item, index) =>
                                            <Table.Row key={index} onClick={() => {
                                                setShowStock({item: _item, show: true})
                                            }}>
                                                <Table.Cell width={1}>{Utils.row_number(index)}</Table.Cell>
                                                <Table.Cell>{_item.item_name}</Table.Cell>
                                                <Table.Cell>{_item.brand_name}</Table.Cell>
                                                <Table.Cell>{_item.category_name}</Table.Cell>
                                                <Table.Cell>{_item.section}</Table.Cell>
                                                <Table.Cell width={2}>{Utils.comma_number(_item.item_price as number)}</Table.Cell>
                                                <Table.Cell width={2}>{Utils.comma_number(_item.item_discount as number)}</Table.Cell>
                                            </Table.Row>
                                        )
                                    }
                                </Table.Body>
                            </Table>
                        </div>

                        <div className="table_footer">
                            <TablePagination
                                total={fetch.total}
                                change={(page: number) => {

                                }}/>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}


import {Dispatch} from "redux";
import {addDatum, updateClient} from "./actions";
import root_reducer from "./reducers";
import {Client, initial_client} from "../components/utils/Data";

/*Dummy state*/
export interface Datum {
    date: Date
    count: number
}

export interface AppState {
    data: Datum[]
    client: Client
}

export const initial_app: AppState = {data: Array<Datum>(), client: initial_client};

/*TYPES*/
export enum Types {
    ADD_DUMMY, UPDATE_CLIENT
}

interface updateClient {
    type: Types.UPDATE_CLIENT
    payload: Client
}

interface AddNewDummy {
    type: Types.ADD_DUMMY
    payload: { data: Datum[], index: number, clear: boolean }
}

/*Then exporting all the action types*/
export type StateActions = AddNewDummy | updateClient

/*DISPATCHING PROPS*/
export const mapStateToProps = (state: ReturnType<typeof root_reducer>) => {
    return {
        data: state.data.data,
        client: state.client.client
    }
};

export const mapDispatchToProps = (dispatch: Dispatch) => ({
    add_datum: (data: Datum[], index: number, clear: boolean) => dispatch(addDatum(data, index, clear)),

    update_client: (client: Client) => dispatch(updateClient(client))
});

/*THESE ARE THE INTERFACES THAT ARE IMPORTED*/
export interface CombinedProps {
    data: Datum[]
    client: Client

    add_datum: (data: Datum[], index: number, clear: boolean) => void
    update_client: (client: Client) => void
}

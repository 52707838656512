import React from "react";
import {Button, Form, Modal, Table, TextArea} from "semantic-ui-react";
import {MessageProps, MyInput, MyLabel, MyMessage, Utils} from "../../utils/Utils";
import {ReqAdmin} from "../../utils/Request";
import update from "react-addons-update";

export interface Category {
    category_id: number
    category_name: string
    category_desc: string
}

export default function Categories(props: {
    show: boolean, close: () => void, categories: Array<Category>, setCategories: (categories: Array<Category>) => void
}) {
    const [message, setMessage] = React.useState<MessageProps>({active: false, message: "", type: 'info'})
    const [saving, setSaving] = React.useState(false)
    const initial_category: Category = {category_desc: "", category_id: 0, category_name: ""}

    const [category, setCategory] = React.useState<Category>(initial_category)
    const handle_category = (name: string, value: string | number) => {
        setCategory({...category, [name]: value})
    }

    const save_category = () => {
        setSaving(true)
        ReqAdmin.save_categories({
            category_desc: category.category_desc, category_id: category.category_id,
            category_name: category.category_name
        })
            .then((result) => {
                if (result.data.hasOwnProperty("code")) {
                    if (result.data['code'] === 1) {
                        const new_category = {...category, 'category_id': result.data['category_id']}
                        if (category.category_id === 0) {
                            props.setCategories([...props.categories, new_category])
                        } else {
                            for (let index = 0; index < props.categories.length; index++) {
                                if (props.categories[index].category_id === category.category_id) {
                                    props.setCategories(update(props.categories, {[index]: {$set: new_category}}))
                                    break
                                }
                            }
                        }
                        setCategory(new_category)
                        setMessage({active: true, message: "Successfully saved category", type: 'success'})
                    } else if (result.data['code'] === 2) {
                        setMessage({active: true, message: "Category is already in use", type: 'error'})
                    }
                } else {
                    setMessage({active: true, message: "Could not save item category", type: 'error'})
                }
                setSaving(false)
            })
            .catch(() => {
                setMessage({active: true, message: "Could not save item category", type: 'error'})
                setSaving(false)
            })
    }

    return (
        <>
            <MyMessage message={message.message} type={message.type} active={message.active}
                       close={() => setMessage({...message, 'active': false})}/>

            <Modal size='small' open={props.show} onClose={props.close}
                   centered={false}>

                <div className="category_container">
                    <div className="row m-0 h-100">
                        <div className="col-12 col-md-7 p-1 item_form">
                            <div>
                                <Table celled striped compact size='small' inverted color='grey' selectable>
                                    <Table.Header>
                                        <Table.Row>
                                            <Table.HeaderCell width={3}>No.</Table.HeaderCell>
                                            <Table.HeaderCell>Category Name</Table.HeaderCell>
                                        </Table.Row>
                                    </Table.Header>
                                    <Table.Body>
                                        {
                                            props.categories.map((category, index) =>
                                                <Table.Row key={category.category_id} onClick={() => setCategory(category)}>
                                                    <Table.Cell width={3}>{Utils.row_number(index)}</Table.Cell>
                                                    <Table.Cell>{category.category_name}</Table.Cell>
                                                </Table.Row>
                                            )
                                        }
                                    </Table.Body>
                                </Table>
                            </div>
                        </div>
                        <div className="col-12 col-md-5 p-1 item_form">
                            <div>
                                <div className="form_container">
                                    <MyLabel label="Category Name"/>
                                    <MyInput placeholder="Enter category name" name="category_name"
                                             value={category.category_name} change={handle_category}/>

                                    <MyLabel label="Category Description"/>
                                    <Form>
                                        <TextArea placeholder='Enter category description' rows={5}
                                                  value={category.category_desc}
                                                  onChange={((event, data) => {
                                                      handle_category('category_desc', data.value as string)
                                                  })}/>
                                    </Form>
                                </div>

                                <div className="button_row">
                                    <div className="row m-0">
                                        <div className="col-6 pl-0 pr-1">
                                            <Button basic fluid size='tiny' content="Clear Data" icon="trash" labelPosition="left"
                                                    onClick={() => setCategory(initial_category)} disabled={saving}/>
                                        </div>
                                        <div className="col-6 pl-1 pr-0">
                                            <Button basic fluid size='tiny' onClick={save_category} content="Save Category"
                                                    icon="save" labelPosition="left" disabled={saving} loading={saving}/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </Modal>
        </>
    )
}
import {Area, AreaChart, CartesianGrid, Tooltip, XAxis, YAxis,} from 'recharts';
import React from "react";

export default function SubjectViewChart(props: { dimensions: { width: number, height: number } }) {
    const data = [
        {"name": "Page A", "uv": 4000}, {"name": "Page B", "uv": 3000}, {"name": "Page C", "uv": 2000},
        {"name": "Page D", "uv": 2780}, {"name": "Page E", "uv": 1890}, {"name": "Page F", "uv": 2390},
        {"name": "Page G", "uv": 3490}
    ]

    return (
        <AreaChart width={props.dimensions.width} height={props.dimensions.height} data={data}
                   margin={{top: 0, right: 0, left: 0, bottom: 0}}>
            <defs>
                <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
                    <stop offset="5%" stopColor="#8884d8" stopOpacity={0.8}/>
                    <stop offset="95%" stopColor="#8884d8" stopOpacity={0}/>
                </linearGradient>
            </defs>
            <XAxis dataKey="name"/>
            <YAxis/>
            <CartesianGrid strokeDasharray="3 3"/>
            <Tooltip/>
            <Area type="monotone" dataKey="uv" stroke="#8884d8" fillOpacity={1} fill="url(#colorUv)"/>
        </AreaChart>
    )
}
import {Description} from "./ModalStock";
import React from "react";
import {Button, Form, Modal, TextArea} from "semantic-ui-react";
import {MessageProps, MyInput, MyLabel, MyMessage} from "../../utils/Utils";

export default function ModalDescription(props: {
    description: Description, handle: (description?: Description) => void, show: boolean
}) {
    const [message, setMessage] = React.useState<MessageProps>({active: false, message: "", type: 'info'})

    const [description, setDescription] = React.useState(props.description)
    const handle_description = (name: string, value: string) => {
        setDescription({...description, [name]: value})
    }

    const save_description = () => {
        if (description.key.trim().length < 4) {
            setMessage({active: true, message: "Title must at least be 4 characters", type: 'error'})
        } else if (description.key.trim().length < 4) {
            setMessage({active: true, message: "Description must at least be 4 characters", type: 'error'})
        } else {
            props.handle(description)
        }
    }

    React.useEffect(() => {
        setDescription(description)
    }, [props.description])

    return (
        <>
            <MyMessage message={message.message} type={message.type} active={message.active}
                       close={() => setMessage({...message, 'active': false})}/>

            <Modal size='mini' open={props.show} onClose={() => props.handle()}>
                <div className="description_container">
                    <MyLabel label="Description Title"/>
                    <MyInput placeholder="Enter title" name="key" value={description.key as string}
                             change={handle_description} className="mb-3"/>

                    <MyLabel label="Description"/>
                    <Form>
                        <TextArea
                            placeholder='Enter description' rows={5} value={description.desc}
                            onChange={((event, data) => {
                                handle_description('desc', data.value as string)
                            })}/>
                    </Form>

                    <div className="row mx-0 mt-3 button_row">
                        <div className="col-6 pl-0 pr-1">
                            <Button basic fluid size='tiny' content="Close Window" icon="close"
                                    labelPosition="left" onClick={() => props.handle()}/>
                        </div>
                        <div className="col-6 pl-1 pr-0">
                            <Button primary fluid size='tiny' content="Save Description"
                                    icon="save" labelPosition="left" onClick={save_description}/>
                        </div>
                    </div>
                </div>
            </Modal>
        </>
    )
}
import {Datum, StateActions, Types} from "./types";
import {Client} from "../components/utils/Data";

export function addDatum(data: Datum[], index: number, clear: boolean): StateActions {
    return {type: Types.ADD_DUMMY, payload: {data, index, clear}}
}

export function updateClient(client: Client): StateActions {
    return {type: Types.UPDATE_CLIENT, payload: client}
}


import React from 'react'
import {CombinedProps, mapDispatchToProps, mapStateToProps} from "../../../store/types";
import {connect} from "react-redux";
import AdminDashboard from "../../admin/AdminDashboard";
import {RouteComponentProps} from "react-router";
import Request from "../Request";
import avatar from '../../../assets/images/avatar.jpg'
import Clients from "../../admin/Clients";
import OrdersFinalised from "../../admin/OrdersFinalised";
import Reports from "../../admin/Reports";
import ShoeStocks from "../../admin/ShoeStocks";
import OrdersPending from "../../admin/OrdersPending";

function AdminNav(props: RouteComponentProps<any> & CombinedProps): any {
    const components = {
        dashboard: {component: <AdminDashboard system={props}/>, title: "DashBoard"},
        clients: {component: <Clients system={props}/>, title: "Clients"},
        pending: {component: <OrdersPending system={props}/>, title: "Pending Orders"},
        finalised: {component: <OrdersFinalised system={props}/>, title: "Finalised Orders"},
        reports: {component: <Reports system={props}/>, title: "Reports"},
        shoes: {component: <ShoeStocks system={props}/>, title: "Shoes"}
    }
    const [content, setContent] = React.useState(components.dashboard)

    const anchor_active = (title: string) => {
        return content.title === title ? 'active' : ''
    }

    React.useEffect(() => {
        document.title = "Wooow shoes | " + content.title
    }, [content])

    return (
        <>
            <div className="app_section">
                <div className="sidebar">
                    <div className="sidebar_header">
                        <img src={avatar} alt={avatar}/>
                        <div>Pande William</div>
                    </div>

                    <div className="sidebar_nav">
                        <ul>
                            <li>
                                <span
                                    onClick={() => setContent(components.dashboard)}
                                    className={anchor_active(components.dashboard.title)}>
                                    <i className="fab fa-microsoft"/> WOOOW Dashboard
                                </span>
                            </li>

                            <li>
                                <span
                                    onClick={() => setContent(components.shoes)}
                                    className={anchor_active(components.shoes.title)}>
                                    <i className="fas fa-shoe-prints"/> Shoe Stock
                                </span>
                            </li>

                            <li>
                                <span
                                    onClick={() => setContent(components.clients)}
                                    className={anchor_active(components.clients.title)}>
                                    <i className="fas fa-users-cog"/> WOOOW Clients
                                </span>
                            </li>
                            <li>
                                <span
                                    onClick={() => setContent(components.pending)}
                                    className={anchor_active(components.pending.title)}>
                                    <i className="fas fa-cart-plus"/> Pending Orders
                                </span>
                            </li>
                            <li>
                                <span
                                    onClick={() => setContent(components.finalised)}
                                    className={anchor_active(components.finalised.title)}>
                                    <i className="fas fa-luggage-cart"/> Finalised Orders
                                </span>
                            </li>
                            <li>
                                <span
                                    onClick={() => setContent(components.reports)}
                                    className={anchor_active(components.reports.title)}>
                                    <i className="fas fa-file-pdf"/> WOOOW Reports
                                </span>
                            </li>
                        </ul>
                    </div>

                </div>
                <section className="app_content">
                    {content.component}
                </section>
            </div>
        </>
    )
}

export default connect(mapStateToProps, mapDispatchToProps)(AdminNav)
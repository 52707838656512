import React, {ChangeEvent} from "react";
import {Button, Form, Image, Modal, Table, TextArea} from "semantic-ui-react";
import {MessageProps, MyInput, MyLabel, MyMessage, MySelect, SelectData, Utils} from "../../utils/Utils";
import {Brand} from "./Brands";
import {Category} from "./Categories";
import ModalDescription from "./ModalDescription";
import {ReqAdmin} from "../../utils/Request";

export interface Description {
    key: string
    desc: string
}

export type item_image = { path: string, file?: File }

export interface Item {
    item_id: number
    category_id: number
    category_name: string
    brand_id: number
    brand_name: string
    item_name: string
    item_desc: string
    item_price: string | number
    item_discount: string | number
    section: 'Men' | 'Boys' | 'Women' | 'Girls'
    descriptions: Array<Description>
    images: Array<item_image>
}

export const initial_item: Item = {
    brand_name: "", category_name: "", images: [], descriptions: [], item_desc: "", item_discount: "", item_price: "",
    brand_id: 0, category_id: 0, item_id: 0, item_name: "", section: 'Men'
}

export default function ModalStock(props: {
    show: boolean, update: (item: Item) => void, brands: Array<Brand>, categories: Array<Category>, close: () => void, item: Item
}) {
    const [message, setMessage] = React.useState<MessageProps>({active: false, message: "", type: 'info'})
    const [saving, setSaving] = React.useState(false)

    const [brands, setBrands] = React.useState<Array<SelectData>>([])
    const [categories, setCategories] = React.useState<Array<SelectData>>([])

    const [itemImage, setItemImage] = React.useState({path: "", index: -1})
    const [item, setItem] = React.useState(initial_item)
    const handle_change = (name: string, value: string | number | Array<item_image> | Array<Description>) => {
        setItem({...item, [name]: value})
    }

    const handle_image = (event: ChangeEvent<HTMLInputElement>) => {
        const files = event.target.files;
        if (files !== null && files.length > 0) {
            const file: File | null = files.item(0);
            if (file !== null) {
                Utils.format_file(file as File)
                    .then(result => {
                        const new_image: item_image = {path: result.file_src, file: file}
                        let new_images = Array<item_image>()

                        item.images.forEach((image, index) => {
                            if (index === itemImage.index) {
                                new_images = [...new_images, new_image]
                            } else {
                                new_images = [...new_images, image]
                            }
                        })

                        if (itemImage.index === -1) {
                            new_images = [...new_images, new_image]
                            setItemImage({index: item.images.length, path: new_image.path})
                        } else {
                            setItemImage({...itemImage, path: new_image.path})
                        }
                        handle_change('images', new_images)
                    })
            }
        }
    }

    const [description, setDescription] = React.useState({description: {key: "", desc: ""}, show: false})
    const handle_description = (description: Description) => {
        let descriptions: Array<Description> = []

        let contains = false;
        item.descriptions.forEach((old_description) => {
            if (old_description.key === description.key) {
                descriptions = [...descriptions, description]
                contains = true
            } else {
                descriptions = [...descriptions, old_description]
            }
        })
        if (!contains) {
            descriptions = [...descriptions, description]
        }
        handle_change('descriptions', descriptions)
    }

    const save_shoe = () => {
        setSaving(true)
        let form_data = new FormData()
        form_data.append("item_id", item.item_id.toString())
        form_data.append("category_id", item.category_id.toString())
        form_data.append("brand_id", item.brand_id.toString())
        form_data.append("item_name", item.item_name)
        form_data.append("section", item.section)
        form_data.append("item_desc", item.item_desc)
        form_data.append("item_price", item.item_price as string)
        form_data.append("item_discount", item.item_discount as string)
        form_data.append("descriptions", JSON.stringify(item.descriptions))

        item.images.forEach((value) => {
            if (value.file !== undefined) {
                form_data.append("image[]", value.file as File)
            }
        })

        ReqAdmin.save_item(form_data)
            .then((response) => {
                setSaving(false)
                if (response.data.hasOwnProperty("code") && response.data['code'] === 1) {
                    setMessage({active: true, message: "Successfully saved item", type: 'success'})

                    let _images: Array<item_image> = [];
                    (response.data['images'] as Array<string>).forEach((value) => {
                        _images = [..._images, {path: Utils.BASE_URL + value}]
                    })
                    let _item: Item = {...item, 'item_id': response.data['item_id'], 'images': _images}
                    setItem(_item)
                    props.update(_item)
                } else {
                    setMessage({active: true, message: "Error while saving item, please retry", type: 'error'})
                }
            })
            .catch(() => {
                setSaving(false)
                setMessage({active: true, message: "Error while saving item, please retry", type: 'error'})
            })
    }

    React.useEffect(() => {
        let new_brands: Array<SelectData> = [{text: "Select a brand name", value: 0}]
        props.brands.forEach((brand) => {
            new_brands = [...new_brands, {text: brand.brand_name, value: brand.brand_id}]
        })
        setBrands(new_brands)
    }, [props.brands])

    React.useEffect(() => {
        let new_categories: Array<SelectData> = [{text: "Select a category name", value: 0}]
        props.categories.forEach((category) => {
            new_categories = [...new_categories, {text: category.category_name, value: category.category_id}]
        })
        setCategories(new_categories)
    }, [props.categories])

    React.useEffect(() => {
        setItem(item)
    }, [props.item])

    return (
        <>
            <MyMessage message={message.message} type={message.type} active={message.active}
                       close={() => setMessage({...message, 'active': false})}/>

            <ModalDescription
                description={description.description} show={description.show}
                handle={(description) => {
                    if (description !== undefined) {
                        handle_description(description)
                    }
                    setDescription({description: {key: "", desc: ""}, show: false})
                }}/>

            <Modal size='small' open={props.show} onClose={props.close}>
                <div className="shoe_container">
                    <div className="row m-0 form_content">
                        <div className="col-12 col-md-6 p-1 item_form">
                            <div>
                                <MyLabel label="Item Name"/>
                                <MyInput placeholder="Enter item name" name="item_name" value={item.item_name}
                                         change={handle_change} className="mb-3"/>

                                <MyLabel label="Item Brand"/>
                                <MySelect
                                    name="brand_id" value={item.brand_id} options={brands} placeholder="Select a brand" className="mb-3"
                                    change={(value, text) => {
                                        setItem({...item, 'brand_id': (value as number), 'brand_name': text})
                                    }}/>

                                <MyLabel label="Item Section"/>
                                <MySelect
                                    name="category_id" value={item.section}
                                    options={
                                        [
                                            {value: 'Men', text: 'Men'}, {value: 'Boys', text: 'Boys'},
                                            {value: 'Girls', text: 'Girls'}, {value: 'Women', text: 'Women'}
                                        ]}
                                    placeholder="Select a section" className="mb-3"
                                    change={(value) => handle_change("section", value as number)}/>

                                <MyLabel label="Item Category"/>
                                <MySelect
                                    name="category_id" value={item.category_id} options={categories}
                                    placeholder="Select a category" className="mb-3"
                                    change={(value, text) => {
                                        setItem({...item, 'category_id': (value as number), 'category_name': text})
                                    }}/>

                                <MyLabel label="Item Price"/>
                                <MyInput placeholder="Enter item price" name="item_price" value={item.item_price as string}
                                         change={handle_change} className="mb-3"/>

                                <MyLabel label="Item Discount"/>
                                <MyInput placeholder="Enter item discount" name="item_discount" value={item.item_discount as string}
                                         change={handle_change} className="mb-3"/>

                                <MyLabel label="Item Description"/>
                                <Form>
                                    <TextArea
                                        placeholder='Enter item description' rows={5} value={item.item_desc}
                                        onChange={((event, data) => {
                                            handle_change('item_desc', data.value as string)
                                        })}/>
                                </Form>
                            </div>
                        </div>

                        <div className="col-12 col-md-6 p-1 item_properties">
                            <div>
                                <div className="images_container">
                                    <div className="image_indicators">
                                        {
                                            item.images.map((image, index) =>
                                                <div className={`image_indicator ${itemImage.index === index ? 'active' : ''}`}
                                                     key={index} title="Click to display image"
                                                     onClick={() => setItemImage({index: index, path: image.path})}/>
                                            )
                                        }
                                    </div>

                                    <div className="collateral_images">
                                        <Image src={itemImage.path}/>
                                    </div>

                                    <div className="row m-0 mt-2">
                                        <div className="col-6 pl-0 pr-1">
                                            {
                                                itemImage.index !== -1 &&
                                                <>
                                                    <input type="file" className="input_file" id="user_avatar"
                                                           onChange={handle_image}/>
                                                    <label htmlFor="user_avatar" className="ui tiny primary button fluid">
                                                        <i className="ui upload icon"/>Update Current
                                                    </label>
                                                </>
                                            }
                                        </div>
                                        <div className="col-6 pl-1 pr-0">
                                            <input type="file" className="input_file" id="new_collateral"
                                                   onChange={handle_image}/>
                                            <label htmlFor="new_collateral" className="ui tiny primary button fluid"
                                                   onClick={() => setItemImage({index: -1, path: ""})}>
                                                <i className="ui upload icon"/>
                                                Add New Image
                                            </label>
                                        </div>
                                    </div>
                                </div>

                                <div className="table_container descriptions">
                                    <Table celled striped compact size='small' inverted color='grey' selectable>
                                        <Table.Header>
                                            <Table.Row>
                                                <Table.HeaderCell width={4}>Title</Table.HeaderCell>
                                                <Table.HeaderCell>Description</Table.HeaderCell>
                                            </Table.Row>
                                        </Table.Header>
                                        <Table.Body>
                                            {
                                                item.descriptions.map((_description, index) =>
                                                    <Table.Row key={index} onClick={() => {
                                                        setDescription({description: _description, show: true})
                                                    }}>
                                                        <Table.Cell width={4}>{_description.key}</Table.Cell>
                                                        <Table.Cell>{_description.desc}</Table.Cell>
                                                    </Table.Row>
                                                )
                                            }
                                        </Table.Body>
                                    </Table>
                                </div>

                                <Button primary floated='right' size='mini' content="Add New" icon="add" labelPosition="left"
                                        onClick={() => setDescription({description: {key: "", desc: ""}, show: true})}/>
                            </div>
                        </div>
                    </div>

                    <div className="row m-0 button_row">
                        <div className="col-6 col-md-4 offset-md-2 pl-0 pr-1">
                            <Button basic fluid size='tiny' disabled={saving} content="Clear Data"
                                    icon="trash" labelPosition="left" onClick={() => setItem(initial_item)}/>
                        </div>
                        <div className="col-6 col-md-4 pl-1 pr-0">
                            <Button primary fluid size='tiny' loading={saving} disabled={saving} content="Save Stock Info"
                                    icon="save" labelPosition="left" onClick={save_shoe}/>
                        </div>
                    </div>
                </div>
            </Modal>
        </>
    )
}